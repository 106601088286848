import { createImageVariantConfig } from './sdkLoader';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

export const limitStringLength = (string, limit) => {
  const stringLength = string.length;
  if (stringLength > limit) {
    const newString = string.slice(0, limit);
    return newString + '...';
  } else {
    return string;
  }
};

export const getListings = () => {
  const aspectWidth = 1;
  const aspectHeight = 1;
  const variantPrefix = 'listing-card';
  const aspectRatio = aspectHeight / aspectWidth;

  return sdk.listings
    .query({
      include: ['author', 'images', 'author.profileImage', 'profileImage'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'description'],
      'fields.user': [
        'profile.displayName',
        'profile.abbreviatedName',
        'profile.publicData',
        'profileImage',
        'profile.profileImage',
      ],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    })
    .then(res => {
      const listings = res.data.data;
      const images =
        res.data.included &&
        res.data.included.filter(i => {
          return i.type === 'image';
        });
      const users =
        res.data.included &&
        res.data.included.filter(i => {
          return i.type === 'user';
        });

      if (users && users.length > 0) {
        users.forEach(u => {
          const imageId = u.relationships.profileImage.data?.id.uuid;

          const luckyImage = images.find(i => {
            return i.id.uuid === imageId;
          });

          u.profileImage = luckyImage;
        });
      }

      if (listings && listings.length > 0) {
        listings.forEach(l => {
          const imageId = l.relationships.images.data[0]?.id.uuid;
          const authorId = l.relationships.author.data?.id.uuid;

          const luckyImage = images.find(i => {
            return i?.id.uuid === imageId;
          });

          const author = users.find(u => {
            return u?.id.uuid === authorId;
          });
          l.author = author;
          l.images = [luckyImage];
        });
      }

      //   const listingsLeftToPush = 12 - listings.length;
      //   let finalListings = [...listings];

      //   for (let i = 1; i <= listingsLeftToPush; i++) {
      //     finalListings.push(listings[getRandomInt(listings.length - 1)]);
      //   }

      let finalListingsFiltered = listings.filter(l => l && l.attributes);

      if (finalListingsFiltered.length < 3) {
        finalListingsFiltered = [
          finalListingsFiltered[0],
          finalListingsFiltered[0],
          finalListingsFiltered[0],
        ];
      }
      return finalListingsFiltered;
    })
    .catch(e => {
      console.log(e);
      return [];
    });
};

import React, { useEffect, useState } from 'react';

import css from './SectionListings.module.css';
import { getListings } from '../../../../util/listings';
import { ListingCard } from '../../../../components';
import SliderOnMobile from '../../../../components/SliderOnMobile/SliderOnMobile';
const SectionListings = props => {
  const { startFrom = 0, title } = props;

  const [listings, setListings] = useState([]);

  useEffect(() => {
    getListings()
      .then(resp => {
        console.log(resp);
        if (resp.length >= startFrom) {
          //setListings(resp.slice(startFrom, startFrom + 5));
          const validListings = resp.slice(startFrom, startFrom + 5).filter(l => l && l.id && l.id.uuid);
          setListings(validListings);
        }
      })
      .catch(e => console.log(e));
  }, []);

  console.log(listings);

  if (listings.length === 0) {
    // Display an empty div or any placeholder when no listings are found
    // return <div className={css.emptyPlaceholder}>No listings found</div>;
    return null;
  }

  return (
    <div className={css.wrapper}>
      {/* <h1 className={css.title}>{title}</h1> */}
      <SliderOnMobile
        desktopContent={
          <>
            <p className={css.desktopTitle}>Listed recently</p>
            <div className={css.desktopContent}>
              {listings.map(l => {
                return (
                  <ListingCard
                    className={css.listingCard}
                    key={l.id.uuid}
                    listing={l}
                    listingExpanded={l}
                    renderSizes={[]}
                    setActiveListing={() => console.log('')}
                  />
                );
              })}
            </div>
          </>
        }
        slidesForMobile={listings.map(l => {
          return (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              listingExpanded={l}
              renderSizes={[]}
              setActiveListing={() => console.log('')}
            />
          );
        })}
        titleForMobile={<p className={css.mobileTitle}>Listed recently</p>}
      />
    </div>
  );
};

export default SectionListings;

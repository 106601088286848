import React from 'react';
import css from './HeroSection.module.css';
import HeroSearchForm from './HeroSearchForm/HeroSearchForm';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../../../util/routes';

const HeroSection = () => {
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();

  const SeachLinkButton = ({ label, searchParams }) => {
    const handleClick = () => {
      history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
    };
    return (
      <div className={css.searchLinkButton} onClick={handleClick}>
        {label}
      </div>
    );
  };

  const searchButtons = [
    {
      label: 'Nike',
      searchParams: {
        keywords: 'nike',
      },
    },
    {
      label: 'Adidas',
      searchParams: {
        keywords: 'adidas',
      },
    },
    {
      label: 'Puma',
      searchParams: {
        keywords: 'puma',
      },
    },

    {
      label: 'New Balance',
      searchParams: {
        keywords: 'new balance',
      },
    },
  ];

  return (
    <div className={css.wrapper}>
      <p className={css.title}>THE SHOWPONY CLUB</p>
      <p className={css.title}>THE ULTIMATE FOOTBALL MARKETPLACE</p>
      <HeroSearchForm history={history} routeConfiguration={routeConfiguration} />
      <div className={css.popularSearchesWrapper}>
        <span className={css.popularSearchesTitle}>Popular searches</span>
        {searchButtons.map(b => {
          return <SeachLinkButton {...b} />;
        })}
      </div>
    </div>
  );
};

export default HeroSection;

import React from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import css from './HeroSearchForm.module.css';
import { Button, FieldTextInput } from '../../../../../components';
import SearchIcon from '../../../../../components/Topbar/SearchIcon';
import { createResourceLocatorString } from '../../../../../util/routes';

const HeroSearchForm = props => {
  const { history, routeConfiguration } = props;

  const onSubmit = values => {
    const keywords = values.keywords;
    const categoryMaybe = keywords ? {} : { pub_category: 'adidas' };
    const searchParams = {
      keywords,
      ...categoryMaybe,
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  };

  const onChange = formValues => {
    console.log(formValues.values);
  };

  return (
    <FinalForm
      onSubmit={onSubmit}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const {
          handleSubmit,
          invalid,
          pristine,
          submitting,
          formName,
          emailSend,
          values,
        } = fieldRenderProps;

        const submitDisabled = invalid || pristine || submitting;
        return (
          <form
            onSubmit={e => {
              e.preventDefault();
              handleSubmit(e);
            }}
            className={css.formWrapper}
          >
            <FormSpy onChange={onChange} />
            <div className={css.searchBarWrapper}>
              <SearchIcon className={css.searchIcon} />
              <FieldTextInput
                className={css.searchInput}
                type="text"
                id="keywords"
                name="keywords"
                placeholder="What are you looking for?"
              />

              <button className={css.submitButton} type="submit">
                Search
              </button>
            </div>
          </form>
        );
      }}
    />
  );
};

export default HeroSearchForm;

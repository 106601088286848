import React from 'react';
import css from './MobileCategoriesNav.module.css';
import { listingFields } from '../../../../config/configListing';
import { NamedLink } from '../../../../components';

const MobileCategoriesNav = () => {
  const allCategories = listingFields.find(i => i.key === 'category')?.enumOptions || [];

  return (
    <div className={css.wrapper}>
      {allCategories.map(c => {
        return (
          <NamedLink
            className={css.link}
            name="SearchPage"
            to={{
              search: `?pub_category=${c.option}`,
            }}
          >
            {c.label}
          </NamedLink>
        );
      })}
    </div>
  );
};

export default MobileCategoriesNav;
